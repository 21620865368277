import React, { useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";

const LogoEdit = () => {

  const [imageUrl, setImageUrl] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageUrl(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        {/* <div className="container pt-4 pb-4">
          <div className="row justify-content-center">
            <div className="col-lg-9">
              <div className="outer-border-box">
                <div className="track">
                  <div className="step active">
                    <span className="icon">
                      {" "}
                      <i className="fa fa-check"></i>{" "}
                    </span>
                    <span className="text">
                      Order confirmed <br />
                      <small>12-09-2023</small>
                    </span>
                  </div>
                  <div className="step active">
                    <span className="icon">
                      {" "}
                      <i className="fa fa-user"></i>{" "}
                    </span>
                    <span className="text">
                      Dispatched <br />
                      <small>12-09-2023</small>
                    </span>
                  </div>
                  <div className="step">
                    <span className="icon">
                      {" "}
                      <i className="fa fa-truck"></i>{" "}
                    </span>
                    <span className="text">
                      On the way <br />
                      <small>12-09-2023</small>
                    </span>
                  </div>
                  <div className="step">
                    <span className="icon">
                      {" "}
                      <i className="fa fa-box"></i>{" "}
                    </span>
                    <span className="text">
                      Ready for pickup <br />
                      <small>12-09-2023</small>
                    </span>
                  </div>
                </div>
                <div className="row my-orderbox pt-4">
                  <div className="col-lg-4">
                    <div className="order-basic-detail">
                      <div>
                        <h6>
                          3D Wooden World <br />
                          Map
                        </h6>
                        <span>QTY : 1</span>
                        <h5>$ 150.00</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-status">
                      <h6>Status</h6>
                      <h5>In Transit</h5>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="Delivery-status">
                      <h6>Delivery Expected by</h6>
                      <h5>15 sept 2023</h5>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-lg-4">
                    <div className="order-basic-info">
                      <h6>
                        Order ID : <span>#09OW1367</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-basic-info">
                      <h6>
                        Order Name : <span>3D WOODEN LAMP</span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-basic-info">
                      <h6>
                        Order placed date : <span>12-09-2023</span>
                      </h6>
                    </div>
                  </div>

                  <div className="col-lg-4">
                    <div className="order-basic-info">
                      <h6>
                        Order Status :<span>Dispatched </span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-basic-info">
                      <h6>
                        Order Color : <span>Red </span>
                      </h6>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="order-basic-info">
                      <h6>
                        Personalized Text : <span>Happy Family</span>
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="Container ">
          <div className="row">
            <div className="col-lg-5">
              <div className="logo-insidebox">
                <div>
                  <h5>Existing Logo</h5>
                  <img
                    id="blah"
                    src="http://placehold.it/180"
                    alt="your logo"
                    className="mt-3"
                  />
                </div>
                <div className="pt-5">
                  <h5>Change Logo</h5>
                  <input
                    type="file"
                    onchange={handleFileChange}
                    className="pt-3"
                  />
                  {imageUrl && (
                    <img
                      src={imageUrl}
                      alt="Preview"
                      style={{ width: "100px", height: "100px" }}
                    />
                  )}
                </div>
                <button type="submit" className="btn btn-success mt-3">
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LogoEdit;
