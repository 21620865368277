import React, { useState } from 'react';
import axios from 'axios';
import './newRegister.css';

const NewRegister = () => {
  const [user, setUser] = useState({
    userType: 'user',
    username: '',
    email: '',
    phone: '',
    password: '',
    carmodel: '',
    motortype: '',
    class_car: '',
    cylinders: '',
    geartype: '',
    manufacture_year: '',
    fueltype: '',
    car_image1: '',
    car_image2: '',
    shopname: '',
    uploadtradelicense: '',
    location: '',
    speciality: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://node.dgtlinnovations.in:4000/api/v1/users/register', user);
      console.log('Response:', response);
      alert('Registration successful');
    } catch (error) {
      alert('Registration failed');
      console.error(error);
    }
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit} className='row form-row'>
      <div className='col-lg-6 form-item'>
        <label>User Type</label>
        <select name="userType" value={user.userType} onChange={handleChange}>
          <option value="user">user</option>
          <option value="shopkeeper">shopkeeper</option>
        </select>
      </div>
      <div className='col-lg-6 form-item'>
        <label>Username</label>
        <input type="text" name="username" value={user.username} onChange={handleChange} required />
      </div>
      <div className='col-lg-6 form-item'>
        <label>Email</label>
        <input type="email" name="email" value={user.email} onChange={handleChange} required />
      </div>
      <div className='col-lg-6 form-item'>
        <label>Phone</label>
        <input type="text" name="phone" value={user.phone} onChange={handleChange} required />
      </div>
      <div className='col-lg-6 col-lg-6 form-item'>
        <label>Password</label>
        <input type="password" name="password" value={user.password} onChange={handleChange} required />
      </div>
      
      {user.userType === 'user' && (
        <>
        <h5>Car Owner</h5>
          <div className='col-lg-6 form-item'>
            <label>Car Model</label>
            <input type="text" name="carmodel" value={user.carmodel} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Motor Type</label>
            <input type="text" name="motortype" value={user.motortype} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Class Car</label>
            <input type="text" name="class_car" value={user.class_car} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Cylinders</label>
            <input type="number" name="cylinders" value={user.cylinders} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Gear Type</label>
            <input type="text" name="geartype" value={user.geartype} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Manufacture Year</label>
            <input type="number" name="manufacture_year" value={user.manufacture_year} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Fuel Type</label>
            <input type="text" name="fueltype" value={user.fueltype} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Car Image 1</label>
            <input type="text" name="car_image1" value={user.car_image1} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Car Image 2</label>
            <input type="text" name="car_image2" value={user.car_image2} onChange={handleChange} required />
          </div>
        </>
      )}

      {user.userType === 'shopkeeper' && (
        <>
        <h5>Shopkeeper</h5>
          <div className='col-lg-6 form-item'>
            <label>Shop Name</label>
            <input type="text" name="shopname" value={user.shopname} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Upload Trade License</label>
            <input type="text" name="uploadtradelicense" value={user.uploadtradelicense} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Location</label>
            <input type="text" name="location" value={user.location} onChange={handleChange} required />
          </div>
          <div className='col-lg-6 form-item'>
            <label>Speciality</label>
            <input type="text" name="speciality" value={user.speciality} onChange={handleChange} required />
          </div>
        </>
      )}

      <div className='form-button'>
      <button type="submit">Register</button>
      </div>
    </form>
    </div>
  );
};

export default NewRegister;
