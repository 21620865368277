import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  
  const adminId = localStorage.getItem('adminId');

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container-fluid">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                </ul>
                <form className="d-flex align-items-center nav-admin">
                    <img src={`${process.env.PUBLIC_URL}/imgs/user-icon.png`} alt="" className="me-2" />
                    <Link  to={`/profile/${adminId}`} className="text-decoration-none"><span>Admin</span></Link>
                </form>
            </div>
        </div>
    </nav>
  )
}

export default Navbar