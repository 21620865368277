import React, { useEffect, useRef, useState } from 'react';
import SideBar from './SideBar';
import $ from 'jquery';
import { Link } from 'react-router-dom';

import { Modal } from "react-bootstrap";
import Navbar from './Navbar';

const BlogsEdit = () => {

  const dataTableRef = useRef();
  const [show, setShow] = useState(false);

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  return (
    <>
    

    <SideBar />
    <Navbar />

    <section className='home'>
    <div className="toggle-sidebar" style={{display: "none"}}>
            <i className='bi bi-menu'></i>
        </div>
        
        <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Delete Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content1">
            <p>Are you sure you want to delete this blog ?</p>
            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="modal-footer-btn" onClick={handleClose}>
            Delete
          </button>
        </Modal.Footer>
      </Modal>

        <div className="container">
            <div className="row foot-tablerow">
                <h5>Edit Blogs Details</h5>
                <div className="col-lg-12 maintable-column">
                    <div className="container mt-4 overflow-auto">
                        <table id="datatable" className="table" ref={dataTableRef}>
                            <thead>
                                <tr> 
                                    <th>#</th>
                                    <th>Blog Image</th>
                                    <th>Blog Content</th>
                                    <th>Date</th>
                                    <th>Category</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td><img src="imgs/blog-img.png" width="200" height="100" alt="" /></td>
                                    <td>Mauris et neque hendrerit</td>
                                    <td>30 April 2024</td>
                                    <td>Case Study</td>
                                    <td><Link to="/blog-content"><i className="fa-regular fa-pen-to-square" ></i></Link> &nbsp;<i
                                            className="fa-solid fa-trash" onClick={handleShow}></i></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td><img src="imgs/Agp-Blog-Jan-3 1.png" width="200" height="100" alt="" /></td>
                                    <td>Mauris et neque hendrerit</td>
                                    <td>30 April 2024</td>
                                    <td>Case Study</td>
                                    <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td><img src="imgs/Agp-Blog-Jan-3 1.png" width="200" height="100" alt="" /></td>
                                    <td>Mauris et neque hendrerit</td>
                                    <td>30 April 2024</td>
                                    <td>Case Study</td>
                                    <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td><img src="imgs/Agp-Blog-Jan-3 1.png" width="200" height="100" alt="" /></td>
                                    <td>Mauris et neque hendrerit</td>
                                    <td>30 April 2024</td>
                                    <td>Case Study</td>
                                    <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td><img src="imgs/Agp-Blog-Jan-3 1.png" width="200" height="100" alt="" /></td>
                                    <td>Mauris et neque hendrerit</td>
                                    <td>30 April 2024</td>
                                    <td>Case Study</td>
                                    <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td><img src="imgs/Agp-Blog-Jan-3 1.png" width="200" height="100" alt="" /></td>
                                    <td>Mauris et neque hendrerit</td>
                                    <td>30 April 2024</td>
                                    <td>Case Study</td>
                                    <td><i className="fa-regular fa-pen-to-square"></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>

                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
        </div>
        </section>
    
    </>
  )
}

export default BlogsEdit;