import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuth } from "../AuthContext";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import moment from "moment";

const DriverPayout = () => {
  const { driverId } = useParams(); // Get driverId from URL parameters
  const { apipath } = useAuth();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [period, setPeriod] = useState("1-15");
  const [payoutData, setPayoutData] = useState(null);
  const [error, setError] = useState("");

  // Handle form submission
  const fetchPayoutData = async () => {
    if (!month || !year) {
      setError("Please select all fields.");
      return;
    }
    setError("");
    try {
      const response = await axios.get(
        `${apipath}/service-service/driverPayout/${driverId}/${month}/${year}/${period}`
      );
      setPayoutData(response.data);
    } catch (err) {
      setError("Error fetching payout data");
      console.error(err);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>
        <div className="container">
          <div className="row foot-tablerow">
            <h5>Driver Payout</h5>
            <div>
              <div class="driver-payout">
                <div class="driver-payout__form">
                  <div>
                    <label for="month">Month:</label>
                    <select
                      id="month"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    >
                      <option value="">Select Month</option>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i + 1} value={i + 1}>
                          {new Date(0, i).toLocaleString("default", {
                            month: "long",
                          })}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <label for="year">Year:</label>
                    <input
                      id="year"
                      type="number"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                      placeholder="Enter Year"
                    />
                  </div>
                  <div>
                    <label for="date-range">Date Range:</label>
                    <select
                      id="date-range"
                      value={period}
                      onChange={(e) => setPeriod(e.target.value)}
                    >
                      <option value="1-15">1-15</option>
                      <option value="16-End">16-End</option>
                    </select>
                  </div>
                  <button onClick={fetchPayoutData} style={{marginTop: "25px"}}>Fetch Payout Data</button>
                </div>
              </div>

              {error && <p style={{ color: "red" }}>{error}</p>}

              {payoutData && (
                <div>
                  <h5>Payout Details</h5>
                  <div class="payout-details">
                    <div class="payout-detail">
                      <p>
                        <span class="label">Service Count:</span>{" "}
                        <span class="value">{payoutData.serviceCount}</span>
                      </p>
                      <p>
                        <span class="label">Total Distance:</span>{" "}
                        <span class="value">{payoutData.totalDistance} km</span>
                      </p>
                      <p>
                        <span class="label">Total Earnings:</span>{" "}
                        <span class="value">₹{payoutData.totalEarning}</span>
                      </p>
                    </div>
                    <div class="payout-detail">
                      <p>
                        <span class="label">Total Cash Payment:</span>{" "}
                        <span class="value">
                          ₹{payoutData.totalCashPayment}
                        </span>
                      </p>
                      <p>
                        <span class="label">Total Online Payment:</span>{" "}
                        <span class="value">
                          ₹{payoutData.totalOnlinePayment}
                        </span>
                      </p>
                      <p>
                        <span class="label">Total Ride Booking Fee:</span>{" "}
                        <span class="value">
                          ₹{payoutData.totalRideBookingFee}
                        </span>
                      </p>
                    </div>
                    <div class="payout-detail">
                      <p>
                        <span class="label">Available Balance:</span>{" "}
                        <span class="value">
                          ₹{payoutData.availableBalance}
                        </span>
                      </p>
                    </div>
                  </div>

                  <h5>Services</h5>
                  <table className="services-table">
                    <thead>
                      <tr>
                        <th>S.No.</th>
                        <th>Booking Order ID</th>
                        <th>Ride Date And Time</th>
                        <th>Ride Booking Fee</th>
                        <th>Ride Distance</th>
                        <th>Ride Amount</th>
                        <th>Payment Method</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payoutData.services.map((service, index) => (
                        <tr key={service._id}>
                          <td>{index + 1}</td>
                          <td>{service.bookingOrderId}</td>
                          <td>{moment(service.createdAt).format('DD-MM-YYYY HH:mm:ss')}</td>
                          <td>₹{service.rideBookingFee}</td>
                          <td>{service.rideDistance} KM</td>
                          <td>₹{service.totalAmount}</td>
                          <td>{service.paymentMethod}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DriverPayout;
