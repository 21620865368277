import React, { useState, useEffect } from 'react';
import SideBar from './SideBar';
import Navbar from './Navbar';
import EtoCoinsModal from './EtoCoinsModal';
import protectedApiInstance from "../utils/protectedApiInstance";
import iziToast from 'izitoast';

const EtoCoinPage = () => {
    const [showModal, setShowModal] = useState(false);
    const [usagePattern, setUsagePattern] = useState(null); // Start with `null` to handle uninitialized state
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(''); // For handling API errors

    useEffect(() => {
        fetchUsagePattern(); // Fetch usage pattern on mount
    }, []);

    const fetchUsagePattern = async () => {
        try {
            setIsLoading(true);
            setError('');
            const response = await protectedApiInstance.get('/admin/eto-coin/usage-pattern');
            
            if (response.status === 200) {
                setUsagePattern(response.data.data);
            } else {
                throw new Error('Failed to fetch usage pattern.');
            }
        } catch (error) {
            console.error('Error fetching usage pattern:', error);
            setError('Failed to fetch usage pattern. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSaveUsagePattern = async (updatedPattern) => {
        try {
            const response = await protectedApiInstance.put('/admin/eto-coin/usage-pattern', updatedPattern);
            if (response.status === 200) {
                setUsagePattern(updatedPattern);
                iziToast.success({
                    title: 'Success',
                    message: 'Usage pattern updated successfully.',
                    position: 'topRight',
                });
            } else {
                throw new Error('Failed to update usage pattern.');
            }
        } catch (error) {
            console.error('Error updating usage pattern:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to update usage pattern.',
                position: 'topRight',
            });
        } finally {
            handleCloseModal();
        }
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container">
                    <div className="row foot-tablerow">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Eto Coins</h5>
                            <button className="btn btn-primary" onClick={handleShowModal} disabled={isLoading}>
                                Edit Usage Pattern
                            </button>
                        </div>

                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4">
                                {isLoading ? (
                                    <p>Loading usage pattern...</p>
                                ) : error ? (
                                    <div>
                                        <p style={{ color: 'red' }}>{error}</p>
                                        <button className="btn btn-secondary" onClick={fetchUsagePattern}>
                                            Retry
                                        </button>
                                    </div>
                                ) : (
                                    <div>
                                        <p><strong>New Bonus w/ Referrer:</strong> {usagePattern.new_bonus_with_referrer}</p>
                                        <p><strong>Referrer Bonus:</strong> {usagePattern.referrer_bonus}</p>
                                        <p><strong>New Bonus w/o Referrer:</strong> {usagePattern.new_bonus_without_referrer}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Edit Usage Pattern Modal */}
            {usagePattern && (
                <EtoCoinsModal
                    show={showModal}
                    onClose={handleCloseModal}
                    onSave={handleSaveUsagePattern}
                    initialValues={usagePattern}
                    fetchUsagePattern={fetchUsagePattern}
                />
            )}
        </>
    );
};

export default EtoCoinPage;
