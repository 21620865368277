import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import BarChart from "../components/BarChart";
import PieChartComp from "../components/PieChartComp";
import axios from "axios";
import { useAuth } from "../AuthContext";

const Main = () => {
  const [showPopup, setShowPopup] = useState(true);
  const [drivers, setDrivers] = useState([]);  
  const [users, setUsers] = useState([]);
  const [completedCount, setCompletedCount] = useState(0);  
  const { apipath } = useAuth();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${apipath}/users/details`);
      const users = response.data.user;

      const totalUsers = users.filter(user => user.role === 'user');
      const totalDrivers = users.filter(user => user.role === 'driver');

      setUsers(totalUsers.length);
      setDrivers(totalDrivers.length);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const response = await axios.get(`${apipath}/booking-service/details`);

        const completedOrders = response.data.booking.filter(booking => booking.bookingStatus === "Completed").length;
        setCompletedCount(completedOrders); // Set the Completed count

      } catch (err) {
        console.error("Failed to fetch bookings. Please try again.");
      }
    };

    fetchBookings();
  }, []);

  return (
    <>
      <SideBar />
      <Navbar />

      <div className="home">
        <div class="toggle-sidebar" style={{ display: "none" }}>
          <i class="bi bi-menu"></i>
        </div>
        <div className="admin-banner">
          <div className="row">
            {/* {showPopup && (
              <div className="col-lg-12 dash-up">
                <div className="dash-strip">
                  <div>
                    <h3>Congrats!! you can now modify your application</h3>
                    <p>
                      Keep all the information up to date with minimal effort
                    </p>
                  </div>
                  <div>
                    <i
                      className="bi bi-x"
                      onClick={() => setShowPopup(false)}
                    ></i>
                  </div>
                </div>
                <div className="over"></div>
              </div>
            )} */}
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Users : 
                </h2>
                {/* <h3 className="pt-2">10</h3> */}
                <ul className="overlapping-avatar">
                  <li>
                    <img src="imgs/user.png" alt="" />
                  </li>
                  <li>
                    <img src="imgs/user2.png" alt="" />
                  </li>
                  <li>
                    <img src="imgs/user3.png" alt="" />
                  </li>
                  <li>
                    <span>{users}+</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-person"></i> Total Registered Drivers : 
                </h2>
                {/* <h3 className="pt-2">10</h3> */}
                <ul className="overlapping-avatar">
                  <li>
                    <img src="imgs/user.png" alt="" />
                  </li>
                  <li>
                    <img src="imgs/user2.png" alt="" />
                  </li>
                  <li>
                    <img src="imgs/user3.png" alt="" />
                  </li>
                  <li>
                    <span>{drivers}+</span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="admin-dashbox">
                <h2>
                  <i className="bi bi-bookmark"></i> Completed Rides : 
                </h2>
                <div className="dash-space">
                  <div>
                    <h3 className="pt-2">{completedCount}</h3>
                    {/* <p>
                      50% <i className="bi bi-arrow-up"></i> from last year
                    </p> */}
                  </div>
                  <div className="dash-img">
                    <img src={`${process.env.PUBLIC_URL}imgs/group 298.png`} alt="car" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                <div className="col-lg-6">
                  <div className="recents">
                    <h5>Recent Activities</h5>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                    <div className="recent-tile">
                      <span>11:25 AM</span>
                      <p>A new User Logged in</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="sales">
                    <div className="sales-1">
                      <h5>Total Shares Worth</h5>
                      <div className="d-flex">
                        <div>
                          <i className="bi bi-tag"></i>
                        </div>
                        <div>
                          <h6>
                            ₹ 3.6 Cr <span>- 34 %</span>
                          </h6>
                          <p>Compared to 4.8 Cr last year</p>
                        </div>
                      </div>
                    </div>
                    <div className="sales-2">
                      <h5>Repeat Rate</h5>
                      <div className="d-flex">
                        <div>
                          <i className="bi bi-tag"></i>
                        </div>
                        <div>
                          <h6>
                            ₹ 3.6 Cr <span>- 34 %</span>
                          </h6>
                          <p>Compared to 4.8 Cr last year</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                  <div className="col-lg-6">
                    <div className="recents">
                      <h5>Sales Comparison</h5>
                      <div className="py-4">
                        <BarChart />
                      </div>
                    </div>
                  </div>
                <div className="col-lg-6">
                  <div className="recents">
                    <h5>Top Sales</h5>
                    <div className="recent-sale">
                      <div className="d-flex align-items-center">
                        <div>
                          <span>SA</span>
                        </div>
                        <div>
                          <h6>Sale Admin</h6>
                          <p>Sales</p>
                        </div>
                      </div>
                      <div>
                        <h5>₹ 5000</h5>
                        <p>900</p>
                      </div>
                    </div>
                    <div className="recent-sale">
                      <div className="d-flex align-items-center">
                        <div>
                          <span>SA</span>
                        </div>
                        <div>
                          <h6>Sale Admin</h6>
                          <p>Sales</p>
                        </div>
                      </div>
                      <div>
                        <h5>₹ 5000</h5>
                        <p>900</p>
                      </div>
                    </div>
                    <div className="recent-sale">
                      <div className="d-flex align-items-center">
                        <div>
                          <span>SA</span>
                        </div>
                        <div>
                          <h6>Sale Admin</h6>
                          <p>Sales</p>
                        </div>
                      </div>
                      <div>
                        <h5>₹ 5000</h5>
                        <p>900</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                <div className="col-lg-6">
                  <div className="recents">
                    <h5>Transactions</h5>
                    <div className="recent-sale">
                      <div className="d-flex align-items-center">
                        <div>
                          <span>SA</span>
                        </div>
                        <div>
                          <h6>Sale Admin</h6>
                          <p>Sales</p>
                        </div>
                      </div>
                      <div>
                        <h5>₹ 5000</h5>
                        <p>900</p>
                      </div>
                    </div>
                    <div className="recent-sale">
                      <div className="d-flex align-items-center">
                        <div>
                          <span>SA</span>
                        </div>
                        <div>
                          <h6>Sale Admin</h6>
                          <p>Sales</p>
                        </div>
                      </div>
                      <div>
                        <h5>₹ 5000</h5>
                        <p>900</p>
                      </div>
                    </div>
                    <div className="recent-sale">
                      <div className="d-flex align-items-center">
                        <div>
                          <span>SA</span>
                        </div>
                        <div>
                          <h6>Sale Admin</h6>
                          <p>Sales</p>
                        </div>
                      </div>
                      <div>
                        <h5>₹ 5000</h5>
                        <p>900</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="recents">
                    <h5>Income Breakdown</h5>
                    <div className="py-4 d-flex justify-content-center">
                      <PieChartComp />
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            {/* <div className="col-lg-12 p-4">
              <div className="row dash-recent">
                <div className="col-lg-12">
                  <div className="recents">
                    <h5>Additional Information</h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
