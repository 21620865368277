import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import iziToast from "izitoast";

const ProfileEdit = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto",
    });
  }, []);

  const [edit, setEdit] = useState(false);

  const handleEdit = () => {
    setEdit(!edit);
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { filepath, apipath } = useAuth();
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState({
    userId: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    city: "",
    state: "",
    country: "",
    dob: "",
  });

  const userId = localStorage.getItem('adminId');

  // Function to check if user is logged in
  const checkLoginStatus = () => {
    const token = localStorage.getItem('usertoken');
    setIsLoggedIn(!!token); // Set login status based on token presence
  };

  useEffect(() => {
  const fetchUserDetails = async () => {
    try {
      const userResponse = await axios.get(apipath + `/users/details/${userId}`);
      if (userResponse.data.user) {
        const user = userResponse.data.user;
        if (user.dob) {
          const formattedDob = new Date(user.dob).toISOString().split('T')[0];
          setUserDetails(prevDetails => ({
            ...prevDetails,
            ...user,
            dob: formattedDob,
          }));
        } else {
          setUserDetails(prevDetails => ({
            ...prevDetails,
            ...user,
          }));
        }
      }
    } catch (error) {
      console.error("Error fetching user details or orders:", error);
    }
  };
  
    fetchUserDetails();
  }, [userId]);

  useEffect(() => {
    checkLoginStatus();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({
      ...userDetails,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    // Convert dob to timestamp
    const { dob, ...otherDetails } = userDetails;
    const dobTimestamp = dob ? new Date(dob).getTime() : null;
    const userDetailsWithTimestamp = { ...otherDetails, dob: dobTimestamp };

    try {
      const response = await axios.put(
        apipath + `/users/details/${userId}`,
        userDetailsWithTimestamp,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        iziToast.success({
          title: 'Success',
          message: 'Profile updated successfully',
          position: 'topCenter',
          timeout: 2000,
        });
        navigate("/adminDashboard");
      } else {
        throw new Error('Failed to update profile');
      }
    } catch (error) {
      console.error('Error submitting user details:', error);
      iziToast.error({
        title: 'Error',
        message: 'Failed to update profile. Please try again later.',
        position: 'topCenter',
        timeout: 2000,
      });
    }
  };

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit Profile Information</h5>
            <div class="col-lg-10 nav-column">
              <div>
                <form>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="fname">First Name</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-person"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="First Name"
                              aria-label="fname"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="lname">Last Name</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-person"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="Last Name"
                              aria-label="lname"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="phone">Phone Number</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-telephone"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="00000 00000"
                              aria-label="phone"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="email">Email ID</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-envelope"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="abc@xyz.com"
                              aria-label="email"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="address">Address</label>
                          <div class="input-group p-group">
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-house-door"></i>
                              </span>
                            </div>
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="Your Address"
                              aria-label="address"
                              aria-describedby="basic-addon1"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="dob">Date of Birth</label>
                          <div class="input-group p-group">
                            <input
                              type="text"
                              class="form-control p-control"
                              placeholder="dd/mm/yyyy"
                              aria-label="dob"
                              aria-describedby="basic-addon1"
                            />
                            <div class="input-group-prepend p-prepend">
                              <span
                                class="input-group-text p-group-text"
                                id="basic-addon1"
                              >
                                <i className="bi bi-calendar"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div className="p-block row">
                      <div className="col-lg-6">
                        <div className="p-field">
                          <label htmlFor="gender">Gender</label>
                          <div class="input-group p-group">
                            <select name="gender" id="gender">
                              <option value="female">Female</option>
                              <option value="male">Male</option>
                              <option value="none">Prefer not to say</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="submit" className="btn btn-success">
                    Update
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfileEdit;
