import iziToast from "izitoast";
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

const SideBar = () => {
  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  // const [activeSubmenu, setActiveSubmenu] = useState(null);
  const location = useLocation();

  const adminname = localStorage.getItem('adminname');
  const adminemail = localStorage.getItem('adminemail');
  const admintoken = localStorage.getItem('admintoken');

  if (!admintoken) {
    navigate("/adminlogin");
  }

  const handleLogout = () => {
    localStorage.removeItem('admintoken');
    localStorage.removeItem('adminname');
    localStorage.removeItem('adminemail');
    localStorage.removeItem('adminId');
    iziToast.success({ message: "Logout successful", position: "topCenter" });
    window.location.href = "/adminlogin";
  };

  // const toggleSubmenu = (index) => {
  //   setActiveSubmenu(activeSubmenu === index ? null : index);
  // };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const isTabActive = (path) => {
    return location.pathname === path ? 'activeTab' : '';
  };

  return (
    <>
      <div className={`sidebar ${isActive ? "close" : ""}`}>
        <Link className="logo-box text-decoration-none" onClick={toggleSidebar}>
          {/* <i className="bi bi-menu-down"></i> */}
          <img className="mt-4 mx-5" src={`${process.env.PUBLIC_URL}/imgs/logo.jpeg`} width={140} height={25} alt="" />
        </Link>

        <ul className="sidebar-list">
          <li>
            <div className="title">
              <Link to="/adminDashboard" className={`link ${isTabActive('/adminDashboard')}`}>
                <i className="bi bi-grid-3x3"></i>
                <span className="name">Dashboard</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/adminDashboard" className="link submenu-title">
                Dashboard
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/users" className={`link ${isTabActive('/usersedit')}`}>
                <i className="bi bi-people"></i>
                <span className="name">Users Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Users Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/drivers" className={`link ${isTabActive('/drivers')}`}>
                <i className="bi bi-person"></i>
                <span className="name">Driver Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/drivers" className="link submenu-title">Driver Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/user-ride-booking" className={`link ${isTabActive('/user-ride-booking')}`}>
                <i className="bi bi-book"></i>
                <span className="name">User Ride Booking</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/user-ride-booking" className="link submenu-title">User Ride Booking</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/driver-ride-accept" className={`link ${isTabActive('/driver-ride-accept')}`}>
                <i className="bi bi-database"></i>
                <span className="name">Driver Ride Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driver-ride-accept" className="link submenu-title">Driver Ride Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/faqedit" className={`link ${isTabActive('/faqedit')}`}>
                <i className="bi bi-cash"></i>
                <span className="name">Billing Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Billing Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/footeredit" className={`link ${isTabActive('/footeredit')}`}>
                <i className="bi bi-gear"></i>
                <span className="name">Payout Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Payout Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/coupons" className={`link ${isTabActive('/footeredit')}`}>
                <i className="bi bi-ticket-perforated"></i>
                <span className="name">Coupon Code Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Coupon Code Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/fare" className={`link ${isTabActive('/footeredit')}`}>
                <i className="bi bi-currency-rupee"></i>
                <span className="name">Fare Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Fare Management</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/locations" className={`link ${isTabActive('/footeredit')}`}>
                <i className="bi bi-geo-alt"></i>
                <span className="name">Locations Management</span>
              </Link>
            </div>
            <div className="submenu">
              <Link className="link submenu-title">Locations Management</Link>
            </div>
          </li>


          <hr /><center><h5>CMS Section</h5></center><hr />

          <li>
            <div className="title">
              <Link to="/eto-coin" className={`link ${isTabActive('/eto-coin')}`}>
                <i className="bi bi-cash-coin"></i>
                <span className="name">Eto Coin</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/eto-coin" className="link submenu-title">Eto Coin</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/user-help-support-options"
                className={`link ${isTabActive('/user-help-support-options')}`}
              >
                <i className="bi bi-info-circle"></i>
                <span className="name">User Help & Support Options</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/user-help-support-options" className="link submenu-title">
                User Help & Support Options
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/user-help-support-contents"
                className={`link ${isTabActive('/user-help-support-contents')}`}
              >
                <i className="bi bi-info-circle"></i>
                <span className="name">User Help & Support Contents</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/user-help-support-contents" className="link submenu-title">
                User Help & Support Contents
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/driver-help-support-options"
                className={`link ${isTabActive('/driver-help-support-options')}`}
              >
                <i className="bi bi-info-circle"></i>
                <span className="name">Driver Help & Support Options</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driver-help-support-options" className="link submenu-title">
                Driver Help & Support Options
              </Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link
                to="/driver-help-support-contents"
                className={`link ${isTabActive('/driver-help-support-contents')}`}
              >
                <i className="bi bi-info-circle"></i>
                <span className="name">Driver Help & Support Contents</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/driver-help-support-contents" className="link submenu-title">
                Driver Help & Support Contents
              </Link>
            </div>
          </li>


          <hr /><center><h5>Policy Section</h5></center><hr />

          <li>
            <div className="title">
              <Link to="/termsedit" className={`link ${isTabActive('/termsedit')}`}>
                <i className="bi bi-shield-fill-check"></i>
                <span className="name">Terms & Conditions</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/termsedit" className="link submenu-title">Terms & Conditions</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/privacyedit" className={`link ${isTabActive('/privacyedit')}`}>
                <i className="bi bi-shield-fill-check"></i>
                <span className="name">Privacy Policy</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/privacyedit" className="link submenu-title">Privacy Policy</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/refundedit" className={`link ${isTabActive('/refundedit')}`}>
                <i className="bi bi-shield-fill-check"></i>
                <span className="name">Refund & Cancellation Policy</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/refundedit" className="link submenu-title">Refund & Cancellation Policy</Link>
            </div>
          </li>

          <li>
            <div className="title">
              <Link to="/shippingedit" className={`link ${isTabActive('/shippingedit')}`}>
                <i className="bi bi-shield-fill-check"></i>
                <span className="name">Shipping & Delivery Policy</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/shippingedit" className="link submenu-title">Shipping & Delivery Policy</Link>
            </div>
          </li>

          {/* <li>
            <div className="title">
              <Link to="/aboutedit" className={`link ${isTabActive('/aboutedit')}`}>
                <i className="bi bi-shield-fill-check"></i>
                <span className="name">About us</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/aboutedit" className="link submenu-title">About us</Link>
            </div>
          </li> */}

          <li>
            <div className="title">
              <Link to="/contactusedit" className={`link ${isTabActive('/contactusedit')}`}>
                <i className="bi bi-shield-fill-check"></i>
                <span className="name">Contact us</span>
              </Link>
            </div>
            <div className="submenu">
              <Link to="/contactusedit" className="link submenu-title">Contact us</Link>
            </div>
          </li>

          {/* <li className="logout">
            <div className="title">
              <Link className="link">
                <i className="bi bi-box-arrow-left"></i>
                <span className="name" onClick={handleLogout}>Logout</span>
              </Link>
            </div>
          </li> */}
          <li className="logout">
            <div className="title">
              <Link to="#" className="link" onClick={handleLogout}>
                <i className="bi bi-box-arrow-left"></i>
                <span className="name">Logout</span>
              </Link>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SideBar;
