import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import Navbar from './Navbar';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import protectedApiInstance from '../utils/protectedApiInstance';
import iziToast from 'izitoast';
import Pagination from '../components/Pagination';

const CouponsPage = () => {
    const [coupons, setCoupons] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isToggleLoading, setIsToggleLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [couponIdToDelete, setCouponIdToDelete] = useState(null);

    const { apipath } = useAuth();

    const handleShowDeleteModal = (id) => {
        setCouponIdToDelete(id);
        setShowDeleteModal(true);
    };

    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    useEffect(() => {
        fetchCoupons();
    }, [currentPage, pageSize, searchQuery]);

    const fetchCoupons = async () => {
        try {
            setIsLoading(true);
            const response = await protectedApiInstance.get('/coupon-code/details', {
                params: {
                    page: currentPage,
                    pageSize,
                    search: searchQuery,
                },
            });
            setCoupons(response.data.couponCodeData);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching coupons:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to fetch coupons',
                position: 'topRight',
            });
            setCoupons([]);
            setTotalPages(0);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleCouponStatus = async (id) => {
      try {
          setIsToggleLoading(true);
  
          // Make a POST request to toggle the status
          const response = await protectedApiInstance.post(`/coupon-code/details/${id}/toggle-status`);
  
          // Update the local coupons state based on the response
          setCoupons(coupons.map((coupon) =>
              coupon._id === id ? { ...coupon, couponEnabled: response.data.coupon.couponEnabled } : coupon
          ));
  
          iziToast.success({
              title: 'Success',
              message: `Coupon status changed to ${response.data.coupon.couponEnabled ? 'enabled' : 'disabled'}`,
              position: 'topRight',
          });
      } catch (error) {
          console.error('Error toggling coupon status:', error);
          iziToast.error({
              title: 'Error',
              message: 'Failed to change status',
              position: 'topRight',
          });
      } finally {
          setIsToggleLoading(false);
      }
  };
  

    const handleDeleteCoupon = async () => {
        try {
            setIsLoading(true);
            await protectedApiInstance.delete(`/coupon-code/details/${couponIdToDelete}`);
            setCoupons(coupons.filter((coupon) => coupon._id !== couponIdToDelete));
            setShowDeleteModal(false);
            iziToast.success({
                title: 'Success',
                message: 'Coupon deleted successfully',
                position: 'topRight',
            });
            fetchCoupons();
        } catch (error) {
            console.error('Error deleting coupon:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to delete coupon',
                position: 'topRight',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) setCurrentPage(page);
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container">
                    <div className="row foot-tablerow">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Coupon Details</h5>
                            <Link to="/coupons/new" className="btn btn-primary">
                                Create Coupon
                            </Link>
                        </div>

                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <div className="table-controls d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <select
                                            id="entriesPerPage"
                                            className="form-select mr-2"
                                            onChange={(e) => setPageSize(parseInt(e.target.value))}
                                            value={pageSize}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        <label htmlFor="entriesPerPage" className="mb-0" style={{ whiteSpace: 'nowrap' }}>
                                            Entries per page
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>

                                <table id="datatable" className="table1">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Name</th>
                                            <th>Code</th>
                                            <th>Discount (%)</th>
                                            <th>Valid From</th>
                                            <th>Valid Until</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {coupons.length > 0 ? (
                                            coupons.map((coupon, index) => (
                                                <tr key={coupon._id}>
                                                    <td className="text-center">
                                                        {(currentPage - 1) * pageSize + index + 1}
                                                    </td>
                                                    <td>{coupon.couponName}</td>
                                                    <td>{coupon.couponCode}</td>
                                                    <td>{coupon.couponDiscount}</td>
                                                    <td>{new Date(coupon.validFrom).toLocaleDateString('en-IN')}</td>
                                                    <td>{new Date(coupon.validUntil).toLocaleDateString('en-IN')}</td>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={coupon.couponEnabled}
                                                                onChange={() => toggleCouponStatus(coupon._id)}
                                                                disabled={isToggleLoading}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <Link to={`/coupons/${coupon._id}`}>
                                                            <i
                                                                className="fa-regular fa-pen-to-square"
                                                                style={{ cursor: 'pointer' }}
                                                            ></i>
                                                        </Link>
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            onClick={() => handleShowDeleteModal(coupon._id)}
                                                            style={{ cursor: 'pointer' }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">No coupons found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div>
                                        {coupons.length > 0
                                            ? `Showing entries ${(currentPage - 1) * pageSize + 1} to ${Math.min(currentPage * pageSize, coupons.length)} of ${coupons.length} entries`
                                            : 'Showing entries 0 to 0 of 0 entries'}
                                    </div>
                                    <Pagination
                                        currentPage={coupons.length > 0 ? currentPage : 0}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Delete Modal */}
            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Coupon</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this coupon?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-footer-btn" onClick={handleDeleteCoupon} disabled={isLoading}>
                        {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default CouponsPage;
