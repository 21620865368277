import React, { useEffect, useRef } from "react";
import SideBar from "./SideBar";
import $ from 'jquery';
import Navbar from "./Navbar";

const FooterEdit = () => {
  const dataTableRef = useRef();

  useEffect(() => {
    $(dataTableRef.current).DataTable();
  }, []);

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Payout Information</h5>
            <div className="col-lg-12 maintable-column">
                    <div className="container mt-4 overflow-auto">
                        <table id="datatable" className="table" ref={dataTableRef}>
                            <thead>
                                <tr> 
                                    <th>#</th>
                                    <th>Text</th>
                                    <th>URL</th>
                                    <th>Order</th>
                                    <th>Column Name</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>Payout</td>
                                    <td>/Payout</td>
                                    <td>1</td>
                                    <td><span className="td-span">Useful Links</span></td>
                                    <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Payout</td>
                                    <td>/Payout</td>
                                    <td>1</td>
                                    <td><span className="td-span">Useful Links</span></td>
                                    <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Payout</td>
                                    <td>/Payout</td>
                                    <td>1</td>
                                    <td><span className="td-span">Useful Links</span></td>
                                    <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Payout</td>
                                    <td>/Payout</td>
                                    <td>1</td>
                                    <td><span className="td-span">Useful Links</span></td>
                                    <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Payout</td>
                                    <td>/Payout</td>
                                    <td>1</td>
                                    <td><span className="td-span">Useful Links</span></td>
                                    <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Payout</td>
                                    <td>/Payout</td>
                                    <td>1</td>
                                    <td><span className="td-span">Useful Links</span></td>
                                    <td><i className="fa-regular fa-pen-to-square" ></i> &nbsp;<i
                                            className="fa-solid fa-trash"></i></td>
                                </tr>
                                

                            </tbody>
                        </table>
                    </div>

                </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterEdit;
