import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import iziToast from 'izitoast'; // For notifications
import protectedApiInstance from "../utils/protectedApiInstance";

const EtoCoinsModal = ({ show, onClose, initialValues = {}, fetchUsagePattern }) => {
    const [usagePattern, setUsagePattern] = useState({
        new_bonus_without_referrer: initialValues.new_bonus_without_referrer || 0,
        referrer_bonus: initialValues.referrer_bonus || 0,
        new_bonus_with_referrer: initialValues.new_bonus_with_referrer || 0,
    });

    const [isSaving, setIsSaving] = useState(false); // To handle saving state

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUsagePattern((prev) => ({
            ...prev,
            [name]: parseFloat(value) || 0, // Ensure numeric values
        }));
    };

    const handleSave = async () => {
        try {
            setIsSaving(true);

            // Make API call to update the usage pattern
            await protectedApiInstance.put('/admin/eto-coin/usage-pattern', {
                new_bonus_without_referrer: usagePattern.new_bonus_without_referrer,
                referrer_bonus: usagePattern.referrer_bonus,
                new_bonus_with_referrer: usagePattern.new_bonus_with_referrer,
            });

            iziToast.success({
                title: 'Success',
                message: 'Usage pattern updated successfully.',
                position: 'topRight',
            });

            fetchUsagePattern(); // Refresh the usage pattern after update
            onClose(); // Close the modal
        } catch (error) {
            console.error('Error updating usage pattern:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to update usage pattern.',
                position: 'topRight',
            });
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <Modal show={show} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Edit EtoCoins Usage Pattern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Welcome Bonus (No Referrer)</Form.Label>
                        <Form.Control
                            type="number"
                            name="new_bonus_without_referrer"
                            value={usagePattern.new_bonus_without_referrer}
                            onChange={handleChange}
                            min={0} // Prevent negative values
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>Referrer Bonus</Form.Label>
                        <Form.Control
                            type="number"
                            name="referrer_bonus"
                            value={usagePattern.referrer_bonus}
                            onChange={handleChange}
                            min={0} // Prevent negative values
                        />
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>New User Bonus (With Referrer)</Form.Label>
                        <Form.Control
                            type="number"
                            name="new_bonus_with_referrer"
                            value={usagePattern.new_bonus_with_referrer}
                            onChange={handleChange}
                            min={0} // Prevent negative values
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose} disabled={isSaving}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleSave} disabled={isSaving}>
                    {isSaving ? 'Saving...' : 'Save Changes'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EtoCoinsModal;
