import React from "react";
import SideBar from "./SideBar";
import TextEditor from "../components/TextEditor";
import Navbar from "./Navbar";

const ContactEdit = () => {
  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div className="container pt-3">
          <div className="row top-barcolumn">
            <h5>Edit Contact Us Information</h5>
            <div className="row nav-column">
              <div>
                <form className="row">
                  <div className="col-lg-12 mb-3">
                    <label for="nav-items1" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Name"
                    />
                  </div>
                  
                  <div className="col-lg-12 mb-3">
                    <TextEditor />
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label for="nav-items1" className="form-label">
                      Contact Address
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Address"
                    >
                    </textarea>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label for="nav-items1" className="form-label">
                      Contact Email
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Email"
                    >
                    </textarea>
                  </div>
                  <div className="col-lg-12 mb-3">
                    <label for="nav-items1" className="form-label">
                      Contact Phone
                    </label>
                    <textarea
                      type="text"
                      className="form-control"
                      id="nav-item1"
                      aria-describedby="nav-item-edit"
                      placeholder="Phone"
                    >
                    </textarea>
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label for="exampleInputEmail1" className="form-label">
                      Top Bar Social Status
                    </label>
                    <div className="radio-formbox">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault1"
                          checked
                        />
                        <label className="form-check-label" for="flexRadioDefault1">
                          Show
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDefault"
                          id="flexRadioDefault2"
                        />
                        <label className="form-check-label" for="flexRadioDefault2">
                          Hide
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <button type="submit" className="btn btn-success">
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactEdit;
