// import React from "react";
// import SideBar from "./SideBar";
// import TextEditor from "../components/TextEditor";
// import Navbar from "./Navbar";

// const PrivacyEdit = () => {
//   return (
//     <>
//       <SideBar />
//       <Navbar />

//       <section className="home">
//         <div className="toggle-sidebar" style={{ display: "none" }}>
//           <i className="bi bi-menu"></i>
//         </div>

//         <div class="container pt-3">
//           <div class="row top-barcolumn">
//             <h5>Edit Privacy Policy Information</h5>
//             <div class="row nav-column">
//               <div>
//                 <form className="row">
//                   <div class="col-lg-6 mb-3">
//                     <label for="nav-items1" class="form-label">
//                       Heading
//                     </label>
//                     <input
//                       type="text"
//                       class="form-control"
//                       id="nav-item1"
//                       aria-describedby="nav-item-edit"
//                       placeholder="Home"
//                     />
//                   </div>
//                   <div class="col-lg-6 mb-3">
//                     <label for="nav-items2" class="form-label">
//                       Sub heading
//                     </label>
//                     <input
//                       type="text"
//                       class="form-control"
//                       id="nav-item2"
//                       aria-describedby="nav-item-edit"
//                       placeholder="Nav Item"
//                     />
//                   </div>
//                   <div className="col-lg-12 mb-3">
//                     <TextEditor />
//                   </div>
//                   <div class="col-lg-6 mb-3">
//                     <label for="exampleInputEmail1" class="form-label">
//                       Status
//                     </label>
//                     <div class="radio-formbox">
//                       <div class="form-check">
//                         <input
//                           class="form-check-input"
//                           type="radio"
//                           name="flexRadioDefault"
//                           id="flexRadioDefault1"
//                           checked
//                         />
//                         <label class="form-check-label" for="flexRadioDefault1">
//                           Show
//                         </label>
//                       </div>
//                       <div class="form-check">
//                         <input
//                           class="form-check-input"
//                           type="radio"
//                           name="flexRadioDefault"
//                           id="flexRadioDefault2"
//                         />
//                         <label class="form-check-label" for="flexRadioDefault2">
//                           Hide
//                         </label>
//                       </div>
//                     </div>
//                   </div>

//                   <div className="">
//                     <button type="submit" className="btn btn-success">
//                       Update
//                     </button>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </>
//   );
// };

// export default PrivacyEdit;
import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import TextEditor from "../components/TextEditor";
import Navbar from "./Navbar";
import axios from "axios";
import { useAuth } from "../AuthContext";
import iziToast from "izitoast";

const PrivacyEdit = () => {
  const [privacyPolicyContent, setPrivacyPolicyContent] = useState("");
  const { apipath } = useAuth();

  useEffect(() => {
    const fetchPrivacyPolicy = async () => {
      try {
        const response = await axios.get(`${apipath}/privacy-policy`);
        setPrivacyPolicyContent(response?.data?.privacyPolicy?.content || "");
        iziToast.success({
          message: "Privacy Policy updated successfully!",
          position: "topCenter"
        });
      } catch (error) {
        console.error("Error fetching privacy policy:", error);
      }
    };

    fetchPrivacyPolicy();
  }, []);

  const handleSavePrivacyPolicy = async (e) => {
    e.preventDefault(); 
    try {
      await axios.post(`${apipath}/privacy-policy`, { content: privacyPolicyContent });
      iziToast.success({
        message: "Privacy Policy updated successfully!",
        position: "topCenter"
      });
      console.log("Privacy policy saved successfully");
    } catch (error) {
      console.error("Error saving privacy policy:", error);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <div class="container pt-3">
          <div class="row top-barcolumn">
            <h5>Edit Privacy Policy Information</h5>
            <div class="row nav-column">
              <div>
                <form className="row">
                  <div className="col-lg-12 mb-3">
                    <TextEditor
                      value={privacyPolicyContent}
                      onChange={setPrivacyPolicyContent}
                    />
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="btn btn-success"
                      onClick={handleSavePrivacyPolicy}
                    >
                      Update
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyEdit;
