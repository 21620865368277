import React, { useEffect, useRef, useState } from "react";
import SideBar from "./SideBar";
import $ from "jquery";
import Navbar from "./Navbar";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { Modal } from "react-bootstrap";
import moment from "moment";

const DriverRideDetails = () => {
  const [show, setShow] = useState(false);
  const [driverRideBookingIdToDelete, setDriverRideBookingIdToDelete] = useState(null);

  const handleClose = () => setShow(false);
  const navigate = useNavigate();
  const [driverRideBookings, setDriverRideBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { apipath } = useAuth();

  const dataTableRef = useRef();

  const handleShow = (id) => {
    setDriverRideBookingIdToDelete(id);
    setShow(true);
  };

  useEffect(() => {
    fetchRideBookings();
    if (driverRideBookings.length > 0 && dataTableRef.current) {
      $(dataTableRef.current).DataTable();
    }
  }, [driverRideBookings]);

  const fetchRideBookings = async () => {
    try {
      const response = await axios.get(apipath + "/service-service/details");
      setDriverRideBookings(response.data.service);
    } catch (error) {
      console.error("Error fetching driver ride booking:", error);
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await axios.delete(
        apipath + `/service-service/details/${driverRideBookingIdToDelete}`
      );
      await fetchRideBookings(); // Refresh courses after deletion
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting driver ride booking:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <SideBar />
      <Navbar />

      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Driver Ride Booking</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this driver ride booking?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="modal-footer-btn"
              onClick={handleDelete}
              disabled={isLoading}
            >
              {isLoading ? "Deleting..." : "Delete"}
            </button>
            <button className="modal-footer-btn" onClick={handleClose}>
              Cancel
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Driver Ride Booking Details</h5>
            <div className="col-lg-11 maintable-column">
              <div className="container mt-4">
                <table id="datatable" className="table" ref={dataTableRef}>
                  <thead>
                    <tr>
                      <th>SN</th>
                      <th>BookingOrderId</th>
                      <th>RideStatus</th>
                      <th>PaymentStatus</th>
                      <th>PaymentMethod</th>
                      <th>RideDistance</th>
                      <th>TotalAmount</th>
                      <th>ServiceDate</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {driverRideBookings.map((driverRideBooking, index) => (
                      <tr key={driverRideBooking._id}>
                        <td className="text-center">{index + 1}</td>
                        <td>{driverRideBooking.bookingOrderId}</td>
                        <td>{driverRideBooking.rideStatus}</td>
                        <td>{driverRideBooking.paymentStatus}</td>
                        <td>{driverRideBooking.paymentMethod}</td>
                        <td>{driverRideBooking.rideDistance}</td>
                        <td>{driverRideBooking.totalAmount}</td>
                        <td>
                          {new Date(
                            driverRideBooking.serviceDate
                          ).toLocaleDateString()}
                        </td>
                        <td>
                          {/* <Link to={`/edit-user-safety-security/${rideBooking._id}`}><i className="fa-regular fa-pen-to-square"></i></Link> &nbsp; */}
                          <i
                            className="fa-solid fa-trash"
                            onClick={() => handleShow(driverRideBooking._id)}
                            style={{ cursor: "pointer" }}
                          ></i>
                          <Link to={`/live-map/${driverRideBooking._id}`}>
                            <i className="bi bi-geo-alt-fill"></i>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DriverRideDetails;
