import axios from "axios";
import React, { useEffect, useState } from "react";
import { useAuth } from "../AuthContext";

const ShippingPolicy = () => {
  const [content, setContent] = useState("");
  const { apipath } = useAuth();

  useEffect(() => {
    const fetchShippingPolicy = async () => {
      try {
        const response = await axios.get(`${apipath}/shipping`);
        setContent(response.data.shippingPolicy.content || "");
      } catch (error) {
        console.error("Error fetching shipping policy:", error);
      }
    };

    fetchShippingPolicy();
  }, []);

  return (
    <>
      <div>
        <img
          src="/imgs/logo.jpeg"
          height={"45px"}
          alt={"logo"}
          style={{ padding: "5px" }}
        />
      </div>
      <div class="container-from-text">
        <div
          className="terms-content"
          style={{
            fontSize: "1rem",
            lineHeight: "1.6",
            color: "#333",
          }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </>
  );
};

export default ShippingPolicy;
