import React, { useEffect, useState } from 'react';
import SideBar from './SideBar';
import Navbar from './Navbar';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import protectedApiInstance from '../utils/protectedApiInstance';
import iziToast from 'izitoast';
import Pagination from '../components/Pagination';
import AddEditLocation from './AddEditLocation'; // Import the AddEditLocation Component

const LocationsPage = () => {
    const [locations, setLocations] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isToggleLoading, setIsToggleLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [show, setShow] = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false); // For creating location modal
    const [locationIdToDelete, setLocationIdToDelete] = useState(null);
    const [locationToEdit, setLocationToEdit] = useState(null); // Location data for editing

    const { apipath, filepath } = useAuth();

    const handleShow = (id) => {
        setLocationIdToDelete(id);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    const handleShowCreateModal = (location = null) => {
        setLocationToEdit(location); // Set location to edit (if any)
        setShowCreateModal(true); // Open the create or edit location modal
    };

    const handleCloseCreateModal = () => {
        setLocationToEdit(null); // Clear any location data after modal is closed
        setShowCreateModal(false); // Close the create or edit location modal
    };

    useEffect(() => {
        fetchLocations();
    }, [currentPage, pageSize, searchQuery]);

    const fetchLocations = async () => {
        try {
            setIsLoading(true);
            const response = await protectedApiInstance.get('/locations/details', {
                params: {
                    page: currentPage,
                    pageSize,
                    search: searchQuery,
                },
            });
            setLocations(response.data.locations);
            setTotalPages(response.data.totalPages);
            setIsLoading(false);
        } catch (error) {
            console.error('Error fetching locations:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to fetch locations',
                position: 'topRight',
            });
            setLocations([]);
            setTotalPages(0);
            setIsLoading(false);
        }
    };

    const toggleLocationStatus = async (id) => {
        try {
            setIsToggleLoading(true);
            await protectedApiInstance.put('/locations/toggle-location-status', { id });
            setLocations(locations.map((location) =>
                location._id === id ? { ...location, status: !location.status } : location
            ));
            iziToast.success({
                title: 'Success',
                message: 'Status changed successfully',
                position: 'topRight',
            });
        } catch (error) {
            console.error('Error toggling location status:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to change status',
                position: 'topRight',
            });
        } finally {
            setIsToggleLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await protectedApiInstance.delete(`/locations/details/${locationIdToDelete}`);
            setLocations(locations.filter((location) => location._id !== locationIdToDelete));
            setShow(false);
            setIsLoading(false);
            iziToast.success({
                title: 'Success',
                message: 'Location deleted successfully',
                position: 'topRight',
            });
            fetchLocations(); // Fetch the updated locations list
        } catch (error) {
            console.error('Error deleting location:', error);
            setIsLoading(false);
            iziToast.error({
                title: 'Error',
                message: 'Failed to delete location',
                position: 'topRight',
            });
        }
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) setCurrentPage(page);
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container">
                    <div className="row foot-tablerow">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Featured Locations</h5>
                            <button className="btn btn-primary" onClick={() => handleShowCreateModal()}>
                                Create Location
                            </button>
                        </div>

                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <div className="table-controls d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <select
                                            id="entriesPerPage"
                                            className="form-select mr-2"
                                            onChange={(e) => setPageSize(parseInt(e.target.value))}
                                            value={pageSize}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        <label htmlFor="entriesPerPage" className="mb-0" style={{ whiteSpace: 'nowrap' }}>
                                            Entries per page
                                        </label>
                                    </div>

                                    <div>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={handleSearchChange}
                                        />
                                    </div>
                                </div>

                                <table id="datatable" className="table1">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Title</th>
                                            <th>City</th>
                                            <th>State</th>
                                            <th>Country</th>
                                            <th>Coordinates</th>
                                            <th>Radius</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {locations.length > 0 ? (
                                            locations.map((location, index) => (
                                                <tr key={location._id}>
                                                    <td className="text-center">
                                                        {(currentPage - 1) * pageSize + index + 1}
                                                    </td>
                                                    <td>{location.title}</td>
                                                    <td>{location.city}</td>
                                                    <td>{location.state}</td>
                                                    <td>{location.country}</td>
                                                    <td>{location.latitude}, {location.longitude}</td>
                                                    <td>{location.radius} km</td>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={location.status}
                                                                onChange={() => toggleLocationStatus(location._id)}
                                                                disabled={isToggleLoading}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <i
                                                            className="fa-regular fa-pen-to-square"
                                                            style={{ cursor: 'pointer' }}
                                                            onClick={() => handleShowCreateModal(location)}  // Open the modal for editing
                                                        ></i>
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            onClick={() => handleShow(location._id)}
                                                            style={{ cursor: 'pointer' }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9" className="text-center">No locations found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div>
                                        {locations.length > 0
                                            ? `Showing entries ${(currentPage - 1) * pageSize + 1} to ${Math.min(currentPage * pageSize, locations.length)} of ${locations.length} entries`
                                            : 'Showing entries 0 to 0 of 0 entries'}
                                    </div>
                                    <Pagination
                                        currentPage={locations.length > 0 ? currentPage : 0}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Delete Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Location</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this location?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                        {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Add/Edit Location Modal */}
            <Modal show={showCreateModal} onHide={handleCloseCreateModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{locationToEdit ? 'Edit Location' : 'Add Location'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <AddEditLocation
                        location={locationToEdit}
                        onClose={handleCloseCreateModal}
                        onSuccess={fetchLocations} // Fetch locations after add/edit
                    />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default LocationsPage;
