import React, { useEffect, useState } from "react";
import SideBar from "./SideBar";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Navbar from "./Navbar";
import { useAuth } from "../AuthContext";
import moment from "moment";
import iziToast from "izitoast";
import protectedApiInstance from "../utils/protectedApiInstance";
import Pagination from "../components/Pagination"; // Assuming you have a Pagination component

const DrivesPage = () => {
  const [show, setShow] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isToggleLoading, setIsToggleLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0); // Start with 0 pages
  const [searchQuery, setSearchQuery] = useState("");
  const { apipath, filepath } = useAuth();
  const [sortOrder, setSortOrder] = useState("desc");

  const handleShow = (id) => {
    setUserIdToDelete(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  useEffect(() => {
    fetchUsers();
  }, [currentPage, pageSize, searchQuery, sortOrder]);

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      const response = await protectedApiInstance.get(`${apipath}/admin/users/details`, {
        params: {
          page: currentPage,
          pageSize,
          search: searchQuery,
          role: "driver",
          sortBy: "createdAt",
          sortOrder,
        },
      });
      setUsers(response.data.users);
      setTotalPages(response.data.totalPages); // Assuming API returns totalPages
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching drivers:", error);
      setUsers([]);
      setTotalPages(0);
      setIsLoading(false);
    }
  };

  const handleSortChange = (order) => {
    if (order !== sortOrder) {
      setSortOrder(order);
      setCurrentPage(1); // Reset to the first page when sort changes
    }
  };

  const handleDelete = async () => {
    try {
      setIsLoading(true);
      await protectedApiInstance.delete(`${apipath}/admin/users/details/${userIdToDelete}`);
      setUsers(users.filter((user) => user._id !== userIdToDelete));
      setShow(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error deleting user:", error);
      setIsLoading(false);
    }
  };

  const toggleUserStatus = async (userId) => {
    try {
      setIsToggleLoading(true);
      await protectedApiInstance.put(`${apipath}/admin/users/toggle-user-status`, { userId });
      setUsers(users.map((user) =>
        user._id === userId ? { ...user, status: !user.status } : user
      ));
      iziToast.success({
        title: "Success",
        message: "Status changed successfully",
        position: "topRight",
      });
    } catch (error) {
      console.error("Error toggling user status:", error);
      iziToast.error({
        title: "Error",
        message: "Failed to change status",
        position: "topRight",
      });
    } finally {
      setIsToggleLoading(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) setCurrentPage(page);
  };

  return (
    <>
      <SideBar />
      <Navbar />
      <section className="home">
        <div className="toggle-sidebar" style={{ display: "none" }}>
          <i className="bi bi-menu"></i>
        </div>

        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Delete Driver</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content1">
              <p>Are you sure you want to delete this driver?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
              {isLoading ? "Deleting..." : "Delete"}
            </button>
          </Modal.Footer>
        </Modal>

        <div className="container">
          <div className="row foot-tablerow">
            <h5>Driver Details</h5>
            <div className="col-lg-12 maintable-column">
              <div className="container mt-4 overflow-auto">
                <div className="table-controls d-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <select
                      id="entriesPerPage"
                      className="form-select mr-2"
                      onChange={(e) => setPageSize(parseInt(e.target.value))}
                      value={pageSize}
                    >
                      <option value={10}>10</option>
                      <option value={25}>25</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                    </select>
                    <label htmlFor="entriesPerPage" className="mb-0" style={{ whiteSpace: "nowrap" }}>
                      Entries per page
                    </label>
                  </div>

                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>

                <table id="datatable" className="table1">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Profile</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th className="d-flex align-items-center">
                        Joined
                        <div className="d-flex flex-column align-items-center">
                          <button
                            className={`btn btn-link px-1 py-0 ${sortOrder === "asc" ? "text-primary" : "text-secondary"}`}
                            onClick={() => handleSortChange("asc")}
                            aria-label="Sort Ascending"
                          >
                            <i className="fas fa-arrow-up"></i>
                          </button>
                          <button
                            className={`btn btn-link px-1 py-0 ${sortOrder === "desc" ? "text-primary" : "text-secondary"}`}
                            onClick={() => handleSortChange("desc")}
                            aria-label="Sort Descending"
                          >
                            <i className="fas fa-arrow-down"></i>
                          </button>
                        </div>

                      </th>
                      <th>Ride Status</th>
                      <th>Account Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.length > 0 ? (
                      users.map((user, index) => (
                        <tr key={user._id}>
                          <td className="text-center">{(currentPage - 1) * pageSize + index + 1}</td>
                          <td className="userImage">
                            <span>
                              <img
                                src={user.profileImg ? filepath + user.profileImg : "imgs/user.png"}
                                alt="User"
                              />
                            </span>
                          </td>
                          <td>{user.firstName} {user.lastName}</td>
                          <td>
                            <span title={user.email} className="span-email">
                              {user.email && user.email.length > 19
                                ? `${user.email.slice(0, 16)}...`
                                : user.email || ""}
                            </span>
                          </td>
                          <td>{user.phone}</td>
                          <td>{moment(user.createdAt).format("DD-MM-YYYY")}</td>
                          <td>
                            {user.isActive ? (
                              <span className="badge bg-success">Active</span>
                            ) : (
                              <span className="badge bg-danger">Inactive</span>
                            )}
                          </td>

                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={user.status}
                                onChange={() => toggleUserStatus(user._id)}
                                disabled={isToggleLoading}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <Link to={`/driver-details-view/${user._id}`}>
                              <i className="fa-regular fa-pen-to-square"></i>
                            </Link>
                            <i
                              className="fa-solid fa-trash"
                              onClick={() => handleShow(user._id)}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="8" className="text-center">No drivers found</td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    {users.length > 0
                      ? `Showing entries ${(currentPage - 1) * pageSize + 1} to ${Math.min(currentPage * pageSize, users.length)} of ${users.length} entries`
                      : "Showing entries 0 to 0 of 0 entries"}
                  </div>
                  <Pagination
                    currentPage={users.length > 0 ? currentPage : 0}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DrivesPage;
