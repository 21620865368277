// import React from "react";
// import 'quill/dist/quill.snow.css'
// import ReactQuill from 'react-quill'

// const TextEditor = ({value, onChange }) => {
  
//   var modules = {
//     toolbar: [
//       [{ size: ["small", false, "large", "huge"] }],
//       ["bold", "italic", "underline", "strike", "blockquote"],
//       [{ list: "ordered" }, { list: "bullet" }],
//       ["link", "image"],
//       [
//         { list: "ordered" },
//         { list: "bullet" },
//         { indent: "-1" },
//         { indent: "+1" },
//         { align: [] }
//       ],
//       [{ "color": ["#000000", "#e60000", "#ff9900", "#ffff00", "#008a00", "#0066cc", "#9933ff", "#ffffff", "#facccc", "#ffebcc", "#ffffcc", "#cce8cc", "#cce0f5", "#ebd6ff", "#bbbbbb", "#f06666", "#ffc266", "#ffff66", "#66b966", "#66a3e0", "#c285ff", "#888888", "#a10000", "#b26b00", "#b2b200", "#006100", "#0047b2", "#6b24b2", "#444444", "#5c0000", "#663d00", "#666600", "#003700", "#002966", "#3d1466", 'custom-color'] }],
//     ]
//   };

//   var formats = [
//     "header", "height", "bold", "italic",
//     "underline", "strike", "blockquote",
//     "list", "color", "bullet", "indent",
//     "link", "image", "align", "size",
//   ];

//   // const handleProcedureContentChange = (content) => {
//   //   console.log("content---->", content);
//   // };

//   return (
//     <div >
//       <div className="text-editor-container">
//         <ReactQuill
//           theme="snow"
//           modules={modules}
//           formats={formats}
//           value={value}
//           onChange={onChange} 
//           placeholder="write your content ...."
//         style={{ height: "200px", width: "100%" }}
//         >
//         </ReactQuill>
//       </div>
//     </div>
//   );

// }

// export default TextEditor;

import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

const TextEditor = ({ value, onChange }) => {
  const editorRef = useRef(null);

  const handleEditorChange = (content) => {
    onChange(content); // Notify parent component of changes
  };

  return (
    <div className="text-editor-container">
      <Editor
        apiKey={process.env.REACT_APP_TINY_MCE_API_KEY} // Replace with your TinyMCE API key
        value={value}
        onEditorChange={handleEditorChange}
        init={{
          height: 400,
          menubar: false,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic underline | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | link image | \
            forecolor backcolor removeformat | code",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </div>
  );
};

export default TextEditor;
