import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SideBar from './SideBar';
import Navbar from './Navbar';
import { Modal } from 'react-bootstrap';
import { useAuth } from '../AuthContext';
import protectedApiInstance from '../utils/protectedApiInstance';
import iziToast from 'izitoast';
import Pagination from '../components/Pagination';

const FarePage = () => {
    const [fares, setFares] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isToggleLoading, setIsToggleLoading] = useState(false);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(''); // Selected location
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(0);
    const [show, setShow] = useState(false);
    const [fareIdToDelete, setFareIdToDelete] = useState(null);

    const { apipath, filepath } = useAuth();

    const handleShow = (id) => {
        setFareIdToDelete(id);
        setShow(true);
    };

    const handleClose = () => setShow(false);

    useEffect(() => {
        fetchFares();
        fetchLocations();
    }, [currentPage, pageSize, selectedLocation]);

    const fetchLocations = async () => {
        try {
            const response = await protectedApiInstance.get('/locations/details');
            setLocations(response.data.locations);
        } catch (error) {
            iziToast.error({
                title: 'Error',
                message: 'Failed to fetch locations',
                position: 'topRight',
            });
        }
    };

    const fetchFares = async () => {
        try {
            setIsLoading(true);
            const response = await protectedApiInstance.get('/fares', {
                params: {
                    page: currentPage,
                    pageSize,
                    location: selectedLocation,
                },
            });
            setFares(response.data.fares);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error('Error fetching fares:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to fetch fares',
                position: 'topRight',
            });
            setFares([]);
            setTotalPages(0);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleFareStatus = async (id) => {
        try {
            setIsToggleLoading(true);
            await protectedApiInstance.patch(`/fares/${id}/toggle-status`);
            setFares(fares.map((fare) =>
                fare._id === id ? { ...fare, current: !fare.current } : fare
            ));
            fetchFares();
            iziToast.success({
                title: 'Success',
                message: 'Status changed successfully',
                position: 'topRight',
            });
        } catch (error) {
            console.error('Error toggling fare status:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to change status',
                position: 'topRight',
            });
        } finally {
            setIsToggleLoading(false);
        }
    };

    const handleDelete = async () => {
        try {
            setIsLoading(true);
            await protectedApiInstance.delete(`/fares/${fareIdToDelete}`);
            setFares(fares.filter((fare) => fare._id !== fareIdToDelete));
            setShow(false);
            iziToast.success({
                title: 'Success',
                message: 'Fare deleted successfully',
                position: 'topRight',
            });
            fetchFares();
        } catch (error) {
            console.error('Error deleting fare:', error);
            iziToast.error({
                title: 'Error',
                message: 'Failed to delete fare',
                position: 'topRight',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handlePageChange = (page) => {
        if (page > 0 && page <= totalPages) setCurrentPage(page);
    };

    return (
        <>
            <SideBar />
            <Navbar />
            <section className="home">
                <div className="container">
                    <div className="row foot-tablerow">
                        <div className="d-flex justify-content-between align-items-center mb-3">
                            <h5>Fare Profiles</h5>
                            <Link to="/fare/new" className="btn btn-primary">
                                Create Fare Profile
                            </Link>
                        </div>

                        <div className="col-lg-12 maintable-column">
                            <div className="container mt-4 overflow-auto">
                                <div className="table-controls d-flex justify-content-between mb-3">
                                    <div className="d-flex align-items-center">
                                        <select
                                            id="entriesPerPage"
                                            className="form-select mr-2"
                                            onChange={(e) => setPageSize(parseInt(e.target.value))}
                                            value={pageSize}
                                        >
                                            <option value={10}>10</option>
                                            <option value={25}>25</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                        </select>
                                        <label htmlFor="entriesPerPage" className="mb-0" style={{ whiteSpace: 'nowrap' }}>
                                            Entries per page
                                        </label>
                                    </div>

                                    <div>
                                        <select
                                            className="form-control"
                                            value={selectedLocation}
                                            onChange={(e) => setSelectedLocation(e.target.value)}
                                        >
                                            <option value="">All Locations</option>
                                            {locations.map((location) => (
                                                <option key={location._id} value={location._id}>
                                                    {location.title}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <table id="datatable" className="table1">
                                    <thead>
                                        <tr>
                                            <th>No</th>
                                            <th>Location</th>
                                            <th>Base Fare</th>
                                            <th>Commission</th>
                                            <th>Night Start</th>
                                            <th>Night End</th>
                                            <th>Current Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fares.length > 0 ? (
                                            fares.map((fare, index) => (
                                                <tr key={fare._id}>
                                                    <td className="text-center">
                                                        {(currentPage - 1) * pageSize + index + 1}
                                                    </td>
                                                    <td>{fare.location?.title || 'N/A'}</td>
                                                    <td>
                                                        {`Three-Seater: Day ${fare.base_fare.three_seater.day}, Night ${fare.base_fare.three_seater.night}`}
                                                    </td>
                                                    <td>{`Day: ${fare.commission.day}, Night: ${fare.commission.night}`}</td>
                                                    <td>{fare.night_starts}</td>
                                                    <td>{fare.night_ends}</td>
                                                    <td>
                                                        <label className="switch">
                                                            <input
                                                                type="checkbox"
                                                                checked={fare.current}
                                                                onChange={() => toggleFareStatus(fare._id)}
                                                                disabled={isToggleLoading}
                                                            />
                                                            <span className="slider round"></span>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <Link to={`/fare/${fare._id}`}>
                                                            <i
                                                                className="fa-regular fa-pen-to-square"
                                                                style={{ cursor: 'pointer' }}
                                                            ></i>
                                                        </Link>
                                                        <i
                                                            className="fa-solid fa-trash"
                                                            onClick={() => handleShow(fare._id)}
                                                            style={{ cursor: 'pointer' }}
                                                        ></i>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" className="text-center">No fares found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <div>
                                        {fares.length > 0
                                            ? `Showing entries ${(currentPage - 1) * pageSize + 1} to ${Math.min(currentPage * pageSize, fares.length)} of ${fares.length} entries`
                                            : 'Showing entries 0 to 0 of 0 entries'}
                                    </div>
                                    <Pagination
                                        currentPage={fares.length > 0 ? currentPage : 0}
                                        totalPages={totalPages}
                                        onPageChange={handlePageChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Delete Modal */}
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Fare</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this fare?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="modal-footer-btn" onClick={handleDelete} disabled={isLoading}>
                        {isLoading ? 'Deleting...' : 'Delete'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default FarePage;
